import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { initiateRecurringPayment } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SHOW_LISTING_REQUEST = 'app/SubscriptionsPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/Subscriptions/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/SubscriptionsPage/SHOW_LISTING_ERROR';

export const SUBSCRIBE_REQUEST = 'app/SubscriptionsPage/SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'app/SubscriptionsPage/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR = 'app/SubscriptionsPage/SUBSCRIBE_ERROR';

// ================ Reducer ================ //

const initialState = {
    listing: null,
    showListingError: null,
    showListingInProgress: false,
    subscribeInProgress: false,
    subscribeError: null,
    sessionURL: null,
};

const subscriptionsPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_LISTING_REQUEST:
            return { ...state, showListingInProgress: true, showListingError: null };
        case SHOW_LISTING_SUCCESS:
            return { ...state, listing: payload, showListingInProgress: false };
        case SHOW_LISTING_ERROR:
            return { ...state, showListingInProgress: false, showListingError: payload };

        case SUBSCRIBE_REQUEST:
            return { ...state, subscribeInProgress: true, subscribeError: null };
        case SUBSCRIBE_SUCCESS:
            return { ...state, subscribeInProgress: false, sessionURL: payload };
        case SUBSCRIBE_ERROR:
            return { ...state, subscribeInProgress: false, subscribeError: payload };

        default:
            return state;
    }
};

export default subscriptionsPageReducer;

// ================ Action creators ================ //

export const showListingRequest = () => ({
    type: SHOW_LISTING_REQUEST,
});

export const showListingSuccess = listing => ({
    type: SHOW_LISTING_SUCCESS,
    payload: listing,
});

export const showListingError = e => ({
    type: SHOW_LISTING_ERROR,
    error: true,
    payload: e,
});

export const subscribeRequest = () => ({ type: SUBSCRIBE_REQUEST });
export const subscribeSuccess = sessionURL => ({ type: SUBSCRIBE_SUCCESS, payload: sessionURL });
export const subscribeError = e => ({ type: SUBSCRIBE_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const showListing = (listingId) => (dispatch, getState, sdk) => {
    dispatch(showListingRequest(listingId));
    const params = {
        id: listingId,
        // include: ['author'],
    };

    return sdk.ownListings.show(params)
        .then(res => {
            dispatch(showListingSuccess(res.data.data));
            return res.data.data;
        })
        .catch(e => {
            dispatch(showListingError(storableError(e)));
        });
};

export const subscribe = ({ listingId, subscriptionName }) => (dispatch, getState, sdk) => {
    dispatch(subscribeRequest());

    const bodyParams = {
        subscriptionName,
        listingId,
        successURL: location.href.split('/').slice(0, -1).join('/'),
        cancelURL: location.href,
    };

    return initiateRecurringPayment(bodyParams)
        .then(response => {
            const { sessionURL, success } = response;

            if (success) {
                dispatch(subscribeSuccess(sessionURL));
            } else {
                dispatch(subscribeError("Something went wrong"));
            };

            return sessionURL;
        })
        .catch(e => {
            dispatch(subscribeError(storableError(e)));
            throw e;
        });
};

export const loadData = (params) => dispatch => {
    const listingId = new UUID(params.id);

    return dispatch(showListing(listingId));
};
