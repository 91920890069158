export const formatCurrency = number => number.toLocaleString('de-DE');

export const calcAge = months => {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  let result = '';
  if (years > 0) {
    result += years + (years === 1 ? ' year' : ' years');
  }
  if (remainingMonths > 0) {
    if (years > 0) {
      result += ' and ';
    }
    result += remainingMonths + (remainingMonths === 1 ? ' month' : ' months');
  }

  return result;
};

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};
